import { DistributionsAccessControlState, distributionsAccessControlReducer, watchDistributionsAccessControlSaga } from "State/AccessControl/DistributionsAccessControlState";
import { combineReducers } from "redux";
import { all } from "typed-redux-saga";

export type AccessControlState = {
  distributions: DistributionsAccessControlState;
};

export function* watchAccessControlSaga() {
  yield all([
    watchDistributionsAccessControlSaga(),
  ]);
}

export const accessControlReducer = combineReducers({
  distributions: distributionsAccessControlReducer,
});