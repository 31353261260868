import { InitializeSmsSignatureCommandResult, postSignatureSms } from "Api/Api";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import { safeApiCall } from "State/Utils";

import { put, throttle } from "typed-redux-saga";
import { ActionType, createAsyncAction, getType } from "typesafe-actions";

export type InitializeSmsSignatureStateType =
  FetchStateType<InitializeSmsSignatureCommandResult>;

export const initializeSmsSignatureState =
  (): InitializeSmsSignatureStateType => getFetchStateDefaultValue();

export type InitializeSmsSignatureActionType = ActionType<
  typeof initializeSmsSignatureAsync
>;

export const initializeSmsSignatureAsync = createAsyncAction(
  "@contract/INITIALIZE_SMS_SIGNATURE_REQUEST",
  "@contract/INITIALIZE_SMS_SIGNATURE_SUCCESS",
  "@contract/INITIALIZE_SMS_SIGNATURE_FAILURE",
)<
  {
    signatureHash: string;
  },
  InitializeSmsSignatureCommandResult,
  Error
>();

function* initializeSmsSignature(
  action: ActionType<typeof initializeSmsSignatureAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(postSignatureSms, {
      signatureHash: action.payload.signatureHash,
    });

    if (error) {
      yield put(initializeSmsSignatureAsync.failure(error));
      return;
    }

    yield put(initializeSmsSignatureAsync.success(response));
  } catch (err) {
    yield put(initializeSmsSignatureAsync.failure(err as Error));
  }
}

export function* InitializeSmsSignatureSaga() {
  yield throttle(
    5000,
    getType(initializeSmsSignatureAsync.request),
    initializeSmsSignature,
  );
}
