import { AppDocumentType, Page } from "Api/Api";
import { PasswordResetNoticeTypes } from "Components/Auth/Passwords/PasswordResetForm";
import { InvestmentDetailTabs } from "Hooks/ContractDetail/useMenuTabs";
import { AppUser } from "State/Auth/Models/AuthStateModels";

const HELPER_URL = "https://example.com";

type IdRouteType = string | number;

export const AppRouting = {
  Purchase: {
    route: `/contract/:id/purchase/:isin?`,
    path: (id: IdRouteType, isin?: string) =>
      `/contract/${encodeURIComponent(id)}/purchase/${encodeURIComponent(
        isin ?? "",
      )}`,
  },
  InvestUninvestedDeposits: {
    route: `/contract/:id/invest-uninvested-deposits/`,
    path: (id: IdRouteType) =>
      `/contract/${encodeURIComponent(id)}/invest-uninvested-deposits`,
  },
  Redemption: {
    route: `/contract/:id/redemption/:isin`,
    path: (id: IdRouteType, isin: string) =>
      `/contract/${encodeURIComponent(id)}/redemption/${encodeURIComponent(
        isin,
      )}`,
  },
  Error403: {
    path: () => `/403`,
  },
  Error404: {
    path: () => `/404`,
  },
  StartPage: {
    path: () => `/`,
  },
  SignIn: {
    path: () => `/sign-in`,
  },
  BankIDCallback: {
    path: () => `/auth/bankID`,
  },
  BankIDCallbackNative: {
    path: () => `/auth/bank-id/native-callback`,
  },
  SignUp: {
    path: () => `/sign-up`,
  },
  ForgottenPassword: {
    route: `/forgotten-password`,
    path: (noticeType?: PasswordResetNoticeTypes) =>
      !!noticeType
        ? `/forgotten-password?noticeType=${encodeURIComponent(noticeType)}`
        : `/forgotten-password`,
  },
  AwaitingEmailVerification: {
    route: `/awaiting-email-verification`,
    path: () => `/awaiting-email-verification`,
  },
  EmailVerification: {
    route: `/email-verification/:token`,
    path: (token: string) => `/email-verification/${encodeURIComponent(token)}`,
  },
  Welcome: {
    path: () => `/welcome`,
  },
  Faq: {
    path: () => `/faq`,
  },
  Account: {
    path: () => `/account`,
  },
  AccountSettings: {
    path: () => `/account-settings`,
  },
  Settings: {
    path: () => `/settings`,
  },
  Contact: {
    path: () => `/contact`,
  },
  Dashboard: {
    path: () => `/dashboard`,
  },
  Biometrics: {
    path: () => `/biometrics-after-sign-up`,
  },
  More: {
    path: () => `/more`,
  },
  ProfileSettings: {
    path: () => `/profile-settings`,
  },
  About: {
    path: () => `/about`,
  },
  PrivacyPolicy: {
    path: () => `/privacy-policy`,
  },
  PersonalData: {
    path: () => `/more/personal-data`,
  },
  Contacts: {
    path: () => `/more/contacts`,
  },
  TermsAndConditions: {
    path: () => `/terms-and-conditions`,
  },
  Contracts: {
    path: () => `/contracts`,
  },
  ContractModeling: {
    path: () => `/contract/modeling`,
  },
  ContractDealer: {
    path: () => `/contract/dealer`,
  },
  ContractDistribution: {
    path: () => `/contract/distribution`,
  },
  PreContractualInformation: {
    path: () => `/contract/pre-contractual-information`,
  },
  ContractCreate: {
    path: () => `/contract/create`,
  },
  ContractDetail: {
    route: `/contract/:id`,
    path: (id: IdRouteType, tab?: InvestmentDetailTabs) =>
      !!tab
        ? `/contract/${encodeURIComponent(id)}?tab=${tab}`
        : `/contract/${encodeURIComponent(id)}`,
  },
  ContractTransferDip: {
    route: `/contract/:id/transfer-dip`,
    path: (id: IdRouteType) =>
      `/contract/${encodeURIComponent(id)}/transfer-dip`,
  },
  ContractCreateBankConnection: {
    route: `/contract/:id/bank-connections/create`,
    path: (id: IdRouteType) =>
      `/contract/${encodeURIComponent(id)}/bank-connections/create`,
  },
  ContractDeactivateBankConnection: {
    route: `/contract/:contractID/bank-connections/:bankAccountID/deactivate`,
    path: (contractID: IdRouteType, bankAccountID: IdRouteType) =>
      `/contract/${encodeURIComponent(
        contractID,
      )}/bank-connections/${encodeURIComponent(bankAccountID)}/deactivate`,
  },
  ContractExchangeAssets: {
    route: `/contract/:id/exchange-assets/:isin`,
    path: (id: IdRouteType, isin: string) =>
      `/contract/${encodeURIComponent(id)}/exchange-assets/${encodeURIComponent(
        isin,
      )}`,
  },
  ContractEdit: {
    route: `/contract/:id/rename`,
    path: (id: IdRouteType) => `/contract/${encodeURIComponent(id)}/rename`,
  },
  MoreDocuments: {
    route: `/more/documents`,
    path: (type?: AppDocumentType) =>
      !!type ? `/more/documents?category=${type}` : `/more/documents`,
  },
  ProfileDeleteAccount: {
    path: () => `/profile-settings/delete-account`,
  },
  ProfileChangePassword: {
    path: () => `/profile-settings/change-password`,
  },
  ProfileChangeEmail: {
    path: () => `/profile-settings/change-email`,
  },
  ProfileChangePhone: {
    path: () => `/profile-settings/change-phone`,
  },
} as const;

type RouteObject<T extends any[] = any[]> = {
  route?: string;
  path: (...args: T) => string;
};

export function getRoute<T extends RouteObject>(routeObj: T): string {
  return routeObj.route ?? routeObj.path();
}

export function getPath<T extends RouteObject>(
  routeObj: T,
  ...args: Parameters<T["path"]>
): string {
  return routeObj.path(...args);
}

export function resolveStartPage(
  user: AppUser | null,
  unauthenticatedUrl: string | null,
  defaultPage: Page,
) {
  if (user === null) {
    return getPath(AppRouting.SignIn);
  }

  if (user.isUnderage) {
    return getPath(AppRouting.Dashboard);
  }

  if (!user.hasAgreedToTermsAndConditionsOfMobileApp) {
    return getPath(AppRouting.TermsAndConditions);
  }

  switch (defaultPage) {
    case Page.ContractCreate:
      return getPath(AppRouting.ContractCreate);
    case Page.Dashboard:
    default:
      if (!!unauthenticatedUrl) {
        const origin = searchURLQuery(unauthenticatedUrl, "origin");
        if (origin === "external") {
          return unauthenticatedUrl;
        }
      }

      return getPath(AppRouting.Dashboard);
  }
}

export function getPathnameAndSearch(location: {
  pathname: string;
  search: string;
}) {
  return `${location.pathname}${location.search}`;
}

function isValidAbsoluteURL(
  url: string,
):
  | { isAbsolute: true; absoluteURL: URL }
  | { isAbsolute: false; absoluteURL: null } {
  try {
    return { isAbsolute: true, absoluteURL: new URL(url) };
  } catch (e) {
    return { isAbsolute: false, absoluteURL: null };
  }
}

function isValidRelativeURL(
  baseUrl: string,
  relativeUrl: string,
):
  | { isRelative: true; absoluteURL: URL }
  | { isRelative: false; absoluteURL: null } {
  try {
    return { isRelative: true, absoluteURL: new URL(relativeUrl, baseUrl) };
  } catch (e) {
    return { isRelative: false, absoluteURL: null };
  }
}

function searchURLQuery(url: string, queryKey: string): string | null {
  // Absolute URL.
  const { isAbsolute, absoluteURL } = isValidAbsoluteURL(url);
  if (isAbsolute) {
    return absoluteURL.searchParams.get(queryKey);
  }

  // Relative URL.
  const { isRelative, absoluteURL: absoluteURLTemp } = isValidRelativeURL(
    HELPER_URL,
    url,
  );
  if (isRelative) {
    return absoluteURLTemp.searchParams.get(queryKey);
  }

  return null;
}

export function addOrUpdateURLQuery(
  url: string,
  key: string,
  value: string,
): string | null {
  const { isAbsolute, absoluteURL } = isValidAbsoluteURL(url);
  if (isAbsolute) {
    absoluteURL.searchParams.set(key, value);
    return absoluteURL.href;
  }

  const { isRelative, absoluteURL: absoluteURLTemp } = isValidRelativeURL(
    HELPER_URL,
    url,
  );

  if (isRelative) {
    absoluteURLTemp.searchParams.set(key, value);
    return absoluteURLTemp.href.substring(HELPER_URL.length);
  }

  return null;
}
