import { Box, Link, Typography } from "@mui/material";
import { ContantForm } from "Components/Shared/Forms/ContantForm";
import { BlCheckboxField } from "Components/Shared/Inputs/BlCheckboxField/BlCheckboxField";
import { Dropdown } from "Components/Shared/Inputs/Dropdown";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { InputHeaderWrapper } from "Components/Shared/Inputs/InputHeaderWrapper";
import { TextInput } from "Components/Shared/Inputs/TextInput";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PageTitle } from "Components/Shared/PageTitle";
import { useInputDateFormatter } from "Hooks/Inputs/useInputDateFormatter";
import { useSexCodeList } from "Hooks/Inputs/useSexCodeList";
import { useCountriesList } from "Hooks/codeLists/useCountriesList";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { ContactInformationFormModel } from "Models/Forms";
import { SexType } from "Models/Inputs";
import { getClientAsync } from "State/Client/Client/GetClient";
import { Resources, useResource } from "Translations/Resources";
import { useEffect, type FunctionComponent, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { Link as RLink } from "react-router-dom";
import { PERSONAL_DATA_ANALYTICS_URL } from "Utils/ExternalLinkUtils";
import { AppDocumentType, ClientDto } from "Api/Api";
import {
  CZECHIA_COUNTRY_CODE,
  SLOVAKIA_COUNTRY_CODE,
} from "Constants/Countries";
import { getMailingAddress, getPermanentAddress } from "Utils/AddressUtils";

const PageResources = Resources.More.PersonalData;
const FormResources = Resources.Forms.PersonalData;

const defaultPersonalIdentificationMask = /^.+$/;
const personalIdentificationMaskForValidatableCitizenships = "000000/0000";
const validatableCitizenships = [CZECHIA_COUNTRY_CODE, SLOVAKIA_COUNTRY_CODE];

const hasValidatableCitizenship = (data: ClientDto | null | undefined) => {
  if (!data) {
    return false;
  }

  if (validatableCitizenships.includes(data.citizenship)) {
    return true;
  }

  return (
    data.otherCitizenships?.some(citizenship =>
      validatableCitizenships.includes(citizenship),
    ) ?? false
  );
};

export const PersonalData: FunctionComponent = _ => {
  const { t } = useResource();

  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector(s => s.client.client);
  const [personalIdentificationMask, setPersonalIdentificationMask] = useState<
    string | RegExp
  >(defaultPersonalIdentificationMask);
  const countriesList = useCountriesList();
  const sexList = useSexCodeList();

  const birthDate = useInputDateFormatter(data?.birthDate);

  useEffect(() => {
    setPersonalIdentificationMask(
      hasValidatableCitizenship(data)
        ? personalIdentificationMaskForValidatableCitizenships
        : defaultPersonalIdentificationMask,
    );
  }, [data]);

  const form = useForm<{ sex: SexType } & ContactInformationFormModel>();
  const {
    reset,
    control,
    formState: { errors },
  } = form;

  useEffect(() => {
    dispatch(getClientAsync.request());
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      const permanentAddress = getPermanentAddress(data?.addresses, true);
      const mailingAddress = getMailingAddress(data?.addresses);

      reset({
        sex: data?.sex,
        addresses: {
          permanentAddress: {
            city: permanentAddress?.city,
            country: permanentAddress?.country,
            street: permanentAddress?.streetName,
            streetConscriptionNumber:
              permanentAddress?.streetConscriptionNumber,
            streetNumber: permanentAddress?.streetNumber,
            postalCode: permanentAddress?.zip,
          },
          mailingAddress: {
            city: mailingAddress?.city,
            country: mailingAddress?.country,
            street: mailingAddress?.streetName,
            streetConscriptionNumber: mailingAddress?.streetConscriptionNumber,
            streetNumber: mailingAddress?.streetNumber,
            postalCode: mailingAddress?.zip,
          },
        },
      });
    }
  }, [data, reset]);

  return (
    <div>
      <PageTitle showOnMobile={false}>
        {t(PageResources.PersonalData)}
      </PageTitle>

      <InputHeaderWrapper header={t(PageResources.PersonalData)}>
        <LoadingWrapper isLoading={isLoading} wrapChildren fullWidth>
          <Dropdown
            label={t(FormResources.PrimaryNationality)}
            disabled
            value={data?.citizenship ?? ""}
            options={countriesList}
            name="citizenship"
          />
          {!!data?.otherCitizenships?.length &&
            data.otherCitizenships.map(value => (
              <Dropdown
                key={value}
                label={t(FormResources.Nationalities)}
                disabled
                value={value}
                options={countriesList}
                name={`citizenship-${value}`}
              />
            ))}
          <TextInput
            value={data?.personalIdentificationNumber}
            label={t(FormResources.PersonalIdentificationNumber)}
            mask={personalIdentificationMask}
            isMaskLazy
            disabled
          />
          <TextInput
            value={birthDate}
            label={t(FormResources.BirthDate)}
            type="date"
            disabled
          />

          <Box marginTop={3}>
            <RadioButtons
              name="sex"
              label={t(Resources.Forms.PersonalData.Gender)}
              codeList={sexList}
              control={control}
              errors={errors}
              disabled
            />
          </Box>

          <TextInput
            value={data?.name?.firstName}
            label={t(FormResources.FirstName)}
            disabled
          />
          <TextInput
            value={data?.name?.lastName}
            label={t(FormResources.LastName)}
            disabled
          />
          <TextInput
            value={data?.name?.titleBefore}
            label={t(FormResources.TitleBeforeName)}
            disabled
          />
          <TextInput
            value={data?.name?.titleAfter}
            label={t(FormResources.TitleAfterName)}
            disabled
          />
        </LoadingWrapper>
      </InputHeaderWrapper>

      <InputHeaderWrapper header={t(PageResources.Constant)}>
        <LoadingWrapper isLoading={isLoading} wrapChildren fullWidth>
          <TextInput
            value={data?.email}
            label={t(FormResources.Email)}
            disabled
          />
          <TextInput
            value={data?.mobile}
            label={t(FormResources.Phone)}
            disabled
            mask={Number}
            inputMode="numeric"
            pattern="[0-9]*"
          />
        </LoadingWrapper>
      </InputHeaderWrapper>

      <FormProvider {...form}>
        <InputHeaderWrapper header={t(PageResources.PermanentAddress)}>
          <LoadingWrapper isLoading={isLoading} wrapChildren fullWidth>
            <ContantForm formType="permanentAddress" disabled />
          </LoadingWrapper>
        </InputHeaderWrapper>

        <LoadingWrapper isLoading={isLoading}>
          <BlCheckboxField
            label={t(FormResources.MailingAddressAsPermanentAddress)}
            checked
            disabled
          />
        </LoadingWrapper>

        {!!data?.addresses?.[1] && (
          <InputHeaderWrapper
            header={t(Resources.Forms.PersonalData.MailingAddress)}
          >
            <LoadingWrapper isLoading={isLoading} wrapChildren fullWidth>
              <ContantForm formType="mailingAddress" disabled />
            </LoadingWrapper>
          </InputHeaderWrapper>
        )}
      </FormProvider>

      <Typography variant="h6" paddingTop={8}>
        {t(PageResources.Information.Title)}
      </Typography>

      <Typography paddingTop={2}>
        {t(PageResources.Information.References.ChangeRequest.Before)}
        <Link
          component={RLink}
          to={getPath(
            AppRouting.MoreDocuments,
            AppDocumentType.FormsAndApplications,
          )}
          fontWeight={600}
          color="secondary"
        >
          {t(PageResources.Information.References.ChangeRequest.Link)}
        </Link>
        {t(PageResources.Information.References.ChangeRequest.After)}
      </Typography>

      <Typography paddingTop={2} color="secondary">
        {t(PageResources.Information.References.Administration)}&nbsp;
        {t(PageResources.Information.References.Analytics.Before)}
        <Link
          target="_blank"
          href={PERSONAL_DATA_ANALYTICS_URL}
          fontWeight={600}
          color="secondary"
        >
          {t(PageResources.Information.References.Analytics.Link)}
        </Link>
      </Typography>
    </div>
  );
};
