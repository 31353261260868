import {
  InvestUninvestedDepositsCommandResultStatus,
  InvestUninvestedDepositsDto,
  getUserContact,
  postContractInvestUninvestedDeposits,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { safeApiCall } from "State/Utils";
import { ApplicationError } from "Models/Errors/ApplicationError";
import { initializeSmsSignatureAsync } from "State/Contracts/Create/SignatureSms/InitializeSmsSignatureState";
import {
  InvestUninvestedDepositsStep,
  setInvestUninvestedDepositsStep,
} from "State/Contracts/UninvestedDeposits/UninvestedDepositsReducer";
import {
  BiometricsSignatureType,
  setBiometricsSignatureType,
} from "State/Biometrics/BiometricsActions";
import { initializeBiometricSignatureAsync } from "State/Contracts/Biometrics/InitializeBiometricSignature";

export const investUninvestedDepositsAsync = createAsyncAction(
  "@contract/INVEST_UNINVESTED_DEPOSITS_REQUEST",
  "@contract/INVEST_UNINVESTED_DEPOSITS_SUCCESS",
  "@contract/INVEST_UNINVESTED_DEPOSITS_FAILURE",
)<
  InvestUninvestedDepositsDto,
  { signatureHash: string; phone?: string; type: "SMS" | "BIOMETRICS" },
  Error
>();

function* investUninvestedDeposits(
  action: ReturnType<typeof investUninvestedDepositsAsync.request>,
): Generator {
  try {
    yield put(
      setInvestUninvestedDepositsStep(InvestUninvestedDepositsStep.Signature),
    );

    const { response, error } = yield* safeApiCall(
      postContractInvestUninvestedDeposits,
      action.payload,
    );
    if (error) {
      yield put(investUninvestedDepositsAsync.failure(error));
      return;
    }

    if (
      response.status !== InvestUninvestedDepositsCommandResultStatus.Success
    ) {
      yield put(
        investUninvestedDepositsAsync.failure(
          new ApplicationError(`Investment create failed, ${response.status}`),
        ),
      );
      return;
    }

    if (response.signatureHashList?.length !== 1) {
      yield put(
        investUninvestedDepositsAsync.failure(
          new ApplicationError("More than one signature hash was returned"),
        ),
      );
    }

    const signatureHash = response.signatureHashList[0];

    if (action.payload.isBiometry) {
      yield put(
        setBiometricsSignatureType(BiometricsSignatureType.UninvestedDeposits),
      );

      yield put(
        initializeBiometricSignatureAsync.request({
          signatureHash,
        }),
      );

      yield put(
        investUninvestedDepositsAsync.success({
          signatureHash: signatureHash,
          type: "BIOMETRICS",
        }),
      );

      return;
    }

    const { response: userContactResponse, error: userContactError } =
      yield* safeApiCall(getUserContact);

    if (userContactError) {
      yield put(
        investUninvestedDepositsAsync.failure(
          new ApplicationError(`UserContact was not fetched successfully.`),
        ),
      );
      return;
    }

    yield put(initializeSmsSignatureAsync.request({ signatureHash }));

    yield put(
      investUninvestedDepositsAsync.success({
        signatureHash: signatureHash,
        phone: userContactResponse.userContactInfo.phone!,
        type: "SMS",
      }),
    );
  } catch (err) {
    yield put(investUninvestedDepositsAsync.failure(err as Error));
  }
}
export function* investUninvestedDepositsSaga() {
  yield takeLatest(
    getType(investUninvestedDepositsAsync.request),
    investUninvestedDeposits,
  );
}
