import { Box, Typography } from "@mui/material";
import { Loader } from "Components/Layout/Loader";
import { FunctionComponent, memo } from "react";

export type BankIDCallbackLoadingWrapperProps = {
  isError: boolean;
  translations: {
    description: string;
    error: string;
  };
};

export const BankIDCallbackLoadingWrapper: FunctionComponent<BankIDCallbackLoadingWrapperProps> =
  memo(({ isError, translations }) => {
    return (
      <Box width="stretch">
        {isError ? (
          <Typography color="error">{translations.error}</Typography>
        ) : (
          <>
            <Typography marginBottom={20}>
              {translations.description}
            </Typography>
            <Loader />
          </>
        )}
      </Box>
    );
  });
