import { BankIDCallback } from "Components/BankID/BankIDCallback";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router";

const ComponentResources = Resources.BankID.Callback;

export const BankIDCallbackPage: FunctionComponent = _ => {
  const { t } = useResource();

  const navigate = useNavigate();
  const onBackClick = useCallback(
    () => navigate(getPath(AppRouting.Dashboard)),
    [navigate],
  );

  const onError = useCallback(
    () => navigate(getPath(AppRouting.ContractCreate)),
    [navigate],
  );

  return (
    <BankIDCallback
      translations={{
        title: t(ComponentResources.Title),
        description: t(ComponentResources.Description),
        backButton: t(ComponentResources.BackButton),
        error: t(Resources.BankID.Error.General),
      }}
      onBackClick={onBackClick}
      onError={onError}
    />
  );
};
