import { USA_NATIONALITY_FORBID_TYPE } from "Constants/Contracts/Create";
import {
  CZECHIA_COUNTRY_CODE,
  SLOVAKIA_COUNTRY_CODE,
  USA_COUNTRY_CODE,
} from "Constants/Countries";
import { PersonalDataFormModel } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { getPersonalDataSchema } from "Utils/Contracts/NewContract/ValidationSchemas/PersonalData";
import { PersonalIdentification } from "Utils/PersonalIdentification";
import { addYears, isBefore, isValid, parse } from "date-fns";
import { ObjectSchema, array, lazy, string } from "yup";

export const ValidatedCountries = [CZECHIA_COUNTRY_CODE, SLOVAKIA_COUNTRY_CODE];

export const UNDER_AGE_TYPE = "under-age";

export const useYupFormSchema = (): ObjectSchema<PersonalDataFormModel> => {
  const { t } = useResource();

  return getPersonalDataSchema({
    customValidations: {
      nationalities: array()
        .min(1, t(Resources.Validation.Required))
        .required()
        .test(
          USA_NATIONALITY_FORBID_TYPE,
          t(Resources.Contract.NewContract.Shared.USACountryPermit),
          value => !value?.some(({ value }) => value === USA_COUNTRY_CODE),
        ),
      personalIdentificationNumber: lazy((_, context) => {
        const nationalities = context.parent
          .nationalities as PersonalDataFormModel["nationalities"];
        const isValidated = nationalities?.some(({ value }) =>
          ValidatedCountries.includes(value),
        );

        if (isValidated) {
          return string()
            .matches(/^(|\d+| )$/, t(Resources.Validation.DigitsOnly))
            .test(
              "personalIdentificationNumber",
              t(Resources.Validation.InvalidPersonalIdentificationNumber),
              value => {
                if (!value) {
                  return false;
                }

                const personIdentification =
                  PersonalIdentification.getInfo(value);

                return personIdentification.IsValid;
              },
            )
            .required();
        }

        return string().required();
      }),
      birthDate: string()
        .required()
        .test(
          UNDER_AGE_TYPE,
          t(Resources.Contract.NewContract.PersonalInformation.UnderAge),
          value => {
            const date = parse(value, "yyyy-MM-dd", new Date());
            if (isValid(date)) {
              // validate is user is at least 18 years old
              return isBefore(addYears(date, 18), new Date());
            }

            // as we only validating minimum age, we don't have to check for bad date format
            return true;
          },
        ),
      birthCountry: string()
        .required()
        .test(
          USA_NATIONALITY_FORBID_TYPE,
          t(Resources.Contract.NewContract.Shared.USACountryPermit),
          value => !(value === USA_COUNTRY_CODE),
        ),
      phone: lazy((_, context) => {
        if (
          String(context.parent.phone).startsWith("420") ||
          String(context.parent.phone).startsWith("421")
        ) {
          return string()
            .min(12, t(Resources.Validation.InvalidPhone))
            .max(12, t(Resources.Validation.InvalidPhone))
            .required();
        } else return string().required();
      }),
    },
  });
};
