import {
  GetClientStateType,
  getClientReducer,
  getClientSaga,
} from "State/Client/Client/GetClient";
import {
  PostContactConfirmationStateType,
  postContactConfirmationReducer,
  postContactConfirmationSaga,
} from "State/Client/ContactConfirmation/PostContactConfirmation";
import {
  PutContactConfirmationStateType,
  putContactConfirmationReducer,
  putContactConfirmationSaga,
} from "State/Client/ContactConfirmation/PutContactConfirmation";
import {
  GetDealersStateType,
  getDealersReducer,
  getDealersSaga,
} from "State/Client/GetDealers/GetDealersState";
import {
  GetExistenceStateType,
  getExistenceReducer,
  getExistenceSaga,
} from "State/Client/GetExistence/GetExistenceState";

import { combineReducers } from "redux";
import { all } from "typed-redux-saga";

export type ClientState = {
  client: GetClientStateType;
  dealers: GetDealersStateType;
  existence: GetExistenceStateType;
  postContactConfirmation: PostContactConfirmationStateType;
  putContactConfirmation: PutContactConfirmationStateType;
};

export function* watchClientSaga() {
  yield all([
    getClientSaga(),
    getDealersSaga(),
    getExistenceSaga(),
    postContactConfirmationSaga(),
    putContactConfirmationSaga(),
  ]);
}

export const clientReducer = combineReducers<ClientState>({
  client: getClientReducer,
  dealers: getDealersReducer,
  existence: getExistenceReducer,
  postContactConfirmation: postContactConfirmationReducer,
  putContactConfirmation: putContactConfirmationReducer,
});
