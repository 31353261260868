import { useEffect, type FunctionComponent } from "react";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { Resources, useResource } from "Translations/Resources";
import { useFormOptions } from "Hooks/Contract/AMLQuestions/useFormOptions";
import { useFormContext } from "react-hook-form";
import {
  AMLQuestionsFormModel,
  TaxResidencyEnum,
} from "State/Contracts/Create/Models";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { DropdownInput } from "Components/Shared/Inputs/Form/DropdownInput";
import { useCountriesList } from "Hooks/codeLists/useCountriesList";
import { InputAdornment } from "@mui/material";
import { BlInfoPopper } from "Components/Shared/BlInfoPopper";

const ComponentResources = Resources.Contract.NewContract.AMLQuestions;

const OtherRequiredFields: TaxResidencyEnum[] = [
  TaxResidencyEnum.Other,
  TaxResidencyEnum.Slovakia,
];

export const TaxResidency: FunctionComponent = _ => {
  const { t } = useResource();
  const { taxResidencyOptions } = useFormOptions();
  const countriesList = useCountriesList();

  const {
    control,
    formState: { errors },
    watch,
    trigger,
    resetField,
  } = useFormContext<AMLQuestionsFormModel>();

  const taxResidency = watch("taxResidency");
  const taxResidencyCountry = watch("taxResidencyCountry");

  useEffect(() => {
    if (taxResidency !== TaxResidencyEnum.Other) {
      resetField("taxResidencyCountry");
    }
    resetField("vatId");
  }, [taxResidency, resetField]);

  useEffect(() => {
    if (taxResidencyCountry) {
      trigger("taxResidencyCountry");
    }
  }, [taxResidencyCountry, trigger]);

  return (
    <div>
      <RadioButtons
        label={t(ComponentResources.TaxResidency)}
        control={control}
        errors={errors}
        name="taxResidency"
        codeList={taxResidencyOptions}
      />

      {taxResidency === TaxResidencyEnum.Other && (
        <DropdownInput
          control={control}
          errors={errors}
          name="taxResidencyCountry"
          label={t(ComponentResources.OtherTaxResidencySelectLabel)}
          options={countriesList}
        />
      )}

      {!!taxResidency && OtherRequiredFields.includes(taxResidency) && (
        <BlInputFormField
          control={control}
          errors={errors}
          name="vatId"
          label={t(ComponentResources.VAT.Label)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <BlInfoPopper>{t(ComponentResources.VAT.Tooltip)}</BlInfoPopper>
              </InputAdornment>
            ),
          }}
        />
      )}
    </div>
  );
};
