import {
  BankIDCallbackLoadingWrapper,
  BankIDCallbackLoadingWrapperProps,
} from "Components/BankID/BankIDCallbackLoadingWrapper";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router";
import { getBankIDProfileNativeFlowAsync } from "State/BankID/GetBankIDProfileNativeFlowState";
import { useResource } from "Translations/Resources";

export type BankIDCallbackNativeProps = Pick<
  BankIDCallbackLoadingWrapperProps,
  "translations"
>;

export const BankIDCallbackNative: FunctionComponent<
  BankIDCallbackNativeProps
> = props => {
  const { t } = useResource();
  const { lastRedirect, error } = useAppSelector(s => s.bankID);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (lastRedirect?.processID && lastRedirect?.processAudience) {
      dispatch(
        getBankIDProfileNativeFlowAsync.request({
          processID: lastRedirect.processID,
          processAudience: lastRedirect.processAudience,
          navigate,
          t,
        }),
      );
    }
  }, [
    lastRedirect?.processID,
    lastRedirect?.processAudience,
    dispatch,
    navigate,
    t,
  ]);

  const isError = !!error;
  return <BankIDCallbackLoadingWrapper isError={isError} {...props} />;
};
