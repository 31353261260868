import * as React from "react";
import { RouteProps, useRoutes } from "react-router-dom";
import { AuthenticatedRoute } from "Routes/AuthenticatedRoute";
import { useSelector } from "react-redux";
import { RootStateType } from "State/Store";
import { AppRouting, getRoute } from "Utils/UrlUtils";
import { BankIDCallbackPage } from "Pages/BankID/BankIDCallbackPage";
import { useAppSelector } from "Hooks/useAppSelector";
import { HealthStatus } from "Api/Api";

import { Error404Page } from "Pages/Errors/Error404Page";
import { Error403Page } from "Pages/Errors/Error403Page";
import { DashboardPage } from "Pages/Dashboard/DashboardPage";
import { BiometricPage } from "Pages/Auth/BiometricPage";
import { EmailVerificationPage } from "Pages/Verifications/EmailVerificationPage";
import { AwaitingEmailVerificationPage } from "Pages/Verifications/AwaitingEmailVerificationPage";
import { ContactPage } from "Pages/Contact/ContactPage";
import { CreatePage as ContractModeling } from "Pages/Contracts/ModelingPage";
import { CreatePage as ContractCreate } from "Pages/Contracts/CreatePage";
import { DealerPage as ContractDealer } from "Pages/Contracts/DealerPage";
import { DistributionPage as ContractDistribution } from "Pages/Contracts/DistributionPage";
import { DetailPage as ContractDetail } from "Pages/Contracts/DetailPage";
import { PurchasePage as PurchaseContract } from "Pages/Contracts/PurchasePage";
import { InvestUninvestedDepositsPage } from "Pages/Contracts/InvestIUninvestedDepositsPage";
import { RedemptionPage } from "Pages/Contracts/RedemptionPage";
import { MorePage } from "Pages/More/MorePage";
import { AboutApplicationPage as AboutPage } from "Pages/About/AboutPage";
import { PersonalDataPage as PersonalData } from "Pages/More/PersonalData";
import { DocumentsPage as MoreDocumentsPage } from "Pages/More/DocumentsPage";
import { SettingsPage as ProfileSettings } from "Pages/Profile/SettingsPage";
import { DeleteAccountPage as DeleteAccount } from "Pages/Profile/DeleteAccountPage";
import { ChangePasswordPage as ChangePassword } from "Pages/Profile/ChangePasswordPage";
import { TermsAndConditionsPage } from "Pages/Auth/TermsAndConditionsPage";
import { MaintenancePage } from "Pages/Errors/MaintenancePage";
import { ForgottenPasswordPage } from "Pages/Auth/ForgottenPasswordPage";
import { StartPage } from "Pages/Auth/StartPage";
import { SignInPage } from "Pages/Auth/SignInPage";
import { SignUpPage } from "Pages/Auth/SignUpPage";
import { PreContractualInformationPage } from "Pages/Contracts/PreContractualInformationPage";
import { TransferDip } from "Pages/Contracts/TransferDip";
import { ContactsPage } from "Pages/More/ContactsPage";
import { CreateBankConnectionPage } from "Pages/Contracts/CreateBankConnectionPage";
import { DeactivateBankConnectionPage } from "Pages/Contracts/DeactivateBankConnectionPage";
import { ExchangeAssetsPage } from "Pages/Contracts/ExchangeAssetsPage";
import { EditPage } from "Pages/Contracts/EditPage";
import { BankIDNativeCallbackPage } from "Pages/BankID/BankIDNativeCallbackPage";

const Routes: React.FunctionComponent<RouteProps> = _ => {
  const isAuthenticated = useSelector(
    (e: RootStateType) => e.auth.isAuthenticated,
  );

  const isUserAuthenticated = isAuthenticated;

  const { apiHealthStatus } = useAppSelector(state => state.health);

  const appRoutes = [
    {
      path: "",
      element: isUserAuthenticated ? (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <DashboardPage />
        </AuthenticatedRoute>
      ) : (
        <StartPage />
      ),
    },
    {
      path: getRoute(AppRouting.SignIn),
      element: <SignInPage />,
    },
    {
      path: getRoute(AppRouting.SignUp),
      element: <SignUpPage />,
    },
    {
      path: getRoute(AppRouting.ForgottenPassword),
      element: <ForgottenPasswordPage />,
    },
    {
      path: getRoute(AppRouting.PreContractualInformation),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <PreContractualInformationPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.Contact),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <ContactPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.Dashboard),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <DashboardPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.EmailVerification),
      element: <EmailVerificationPage />,
    },
    {
      path: getRoute(AppRouting.AwaitingEmailVerification),
      element: <AwaitingEmailVerificationPage />,
    },
    {
      path: getRoute(AppRouting.Biometrics),
      element: <BiometricPage />,
    },
    {
      path: getRoute(AppRouting.ContractModeling),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <ContractModeling />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.ContractCreate),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <ContractCreate />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.ContractDealer),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <ContractDealer />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.ContractDistribution),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <ContractDistribution />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.ContractDetail),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <ContractDetail />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.Purchase),
      element: (
        <AuthenticatedRoute
          isUserAuthenticated={isUserAuthenticated}
          forbidUnderage
        >
          <PurchaseContract />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.InvestUninvestedDeposits),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <InvestUninvestedDepositsPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.Redemption),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <RedemptionPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.ContractTransferDip),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <TransferDip />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.ContractCreateBankConnection),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <CreateBankConnectionPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.ContractDeactivateBankConnection),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <DeactivateBankConnectionPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.ContractExchangeAssets),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <ExchangeAssetsPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.ContractEdit),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <EditPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.More),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <MorePage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.MoreDocuments),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <MoreDocumentsPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.Contacts),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <ContactsPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.About),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <AboutPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.PersonalData),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <PersonalData />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.ProfileSettings),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <ProfileSettings />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.ProfileDeleteAccount),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <DeleteAccount />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.ProfileChangePassword),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <ChangePassword />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.TermsAndConditions),
      element: <TermsAndConditionsPage />,
    },
    {
      path: getRoute(AppRouting.BankIDCallback),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <BankIDCallbackPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.BankIDCallbackNative),
      element: <BankIDNativeCallbackPage />,
    },
    {
      path: getRoute(AppRouting.Error403),
      element: <Error403Page />,
    },
    { path: "*", element: <Error404Page /> },
  ];

  const maintananceRoutes = [
    {
      path: "*",
      element: <MaintenancePage />,
    },
  ];

  const element = useRoutes(
    apiHealthStatus === HealthStatus.Operational
      ? appRoutes
      : maintananceRoutes,
  );

  return element;
};

export { Routes };
