import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useMemo } from "react";
import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";
import { DashboardIcon, OtherIcon, PlusIcon } from "Components/Shared/Icons";
import { resetNewContract } from "State/Contracts/Shared/Actions";
import { track } from "Utils/TrackingUtils";
import { BROKER_CONSULTING_DISTRIBUTION_ID } from "Utils/DistributionUtils";
import { useLocation } from "react-router";
import { useDistributionsAccessControlRedirect } from "Hooks/AccessControl/useDistributionsAccessControlRedirect";

const MenuResources = Resources.Navigation;

export type MenuItem = {
  id: string;
  label: string;
  icon: JSX.Element;
  value: string;
  onClick?: () => void;
  disabled?: boolean;
};

export const useMenuItems = () => {
  const { t } = useResource();
  const { pathname } = useLocation();
  const { user } = useAppSelector(s => s.auth);
  const {
    isAccessRestricted,
    isValidated: isAccessControlValidated,
    distributionID,
  } = useDistributionsAccessControlRedirect();

  const dispatch = useAppDispatch();
  const items: MenuItem[] = useMemo(
    () => [
      {
        id: "dashboard",
        label: t(MenuResources.Dashboard),
        icon: <DashboardIcon />,
        value: getPath(AppRouting.Dashboard),
        onClick: () => trackMenuItemClick(pathname, t(MenuResources.Dashboard)),
      },
      {
        id: "newInvestment",
        label: t(MenuResources.NewInvestment),
        icon: <PlusIcon />,
        onClick: () => {
          trackMenuItemClick(pathname, t(MenuResources.NewInvestment));
          dispatch(resetNewContract());
        },
        value: isAccessRestricted
          ? distributionID === Number(BROKER_CONSULTING_DISTRIBUTION_ID)
            ? getPath(AppRouting.ContractDistribution)
            : getPath(AppRouting.ContractDealer)
          : getPath(AppRouting.ContractModeling),
        disabled: !isAccessControlValidated || user?.isUnderage,
      },
      {
        id: "more",
        label: t(MenuResources.More),
        icon: <OtherIcon />,
        value: getPath(AppRouting.More),
        onClick: () => trackMenuItemClick(pathname, t(MenuResources.More)),
      },
    ],
    [
      isAccessRestricted,
      isAccessControlValidated,
      distributionID,
      pathname,
      user?.isUnderage,
      t,
      dispatch,
    ],
  );

  return { items };
};

const trackMenuItemClick = (url: string, value: string) => {
  track({
    category: "Button",
    event: "MenuClick",
    action: "Click",
    url: url,
    value: value,
  });
};
