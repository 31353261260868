import { createAction } from "typesafe-actions";
import { takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { WebViewMessage, WebViewMessageTypes } from "Models/WebViewModels";
import { put } from "typed-redux-saga";
import { signUpBiometricsAsync } from "State/Auth/Biometrics/BiometricSignUpState";
import { signInBiometricsAsync } from "State/Auth/Biometrics/BiometricSignInState";
import { handleWebViewInitAsync } from "State/Auth/Biometrics/HandleWebViewInitState";

import { deleteBiometricsAsync } from "State/Biometrics/Disable/DeleteBiometricsState";
import { signWithBiometryAsync } from "State/Contracts/Biometrics/SignWithBiometryState";
import { getTrackingSessionID } from "Utils/TrackingUtils";
import { NavigateFunction } from "react-router";
import {
  setSettingBiometricsInProgress,
  setBiometrics,
} from "State/Biometrics/BiometricsActions";
import { isNoU } from "Utils/ObjectUtils";
import { logError } from "ErrorService";
import { ApplicationError, ErrorLevel } from "Models/Errors/ApplicationError";
import {
  handleEnableNotificationsResponse,
  handleNotificationTokenResponse,
} from "State/Notifications/NotificationsReducer";

export const handleOnWebViewMessage = createAction("@webView/listen")<{
  message: WebViewMessage;
  navigate: NavigateFunction;
  t: (resourcePath: string, options?: any) => string;
}>();

function* listenOnWebView(
  action: ReturnType<typeof handleOnWebViewMessage>,
): Generator {
  try {
    const { message, navigate } = action.payload;

    console.log("listenOnWebView", action);
    console.log(`Received ${message.type}`);
    if (message.type === WebViewMessageTypes.INIT_RESPONSE) {
      yield* put(handleWebViewInitAsync.request(message.payload));
      return;
    }

    if (message.type === WebViewMessageTypes.BIOMETRIC_SIGN_UP_RESPONSE) {
      if (message.payload === undefined) {
        logPayloadIsUndefinedError();
        return;
      }

      yield* put(
        signUpBiometricsAsync.request({
          ...message.payload,
          navigate,
        }),
      );
      return;
    }

    if (
      message.type === WebViewMessageTypes.BIOMETRIC_SIGN_UP_RESULT_RESPONSE
    ) {
      if (message.payload === undefined) {
        logPayloadIsUndefinedError();
        return;
      }

      yield* put(setSettingBiometricsInProgress(false));
      yield* put(setBiometrics(message.payload.isBiometricsEnabled));
      return;
    }

    if (message.type === WebViewMessageTypes.BIOMETRIC_SIGN_IN_RESPONSE) {
      if (message.payload === undefined) {
        logPayloadIsUndefinedError();
        return;
      }

      yield* put(
        signInBiometricsAsync.request({
          ...message.payload,
          trackingSessionID: getTrackingSessionID(),
          navigate,
        }),
      );

      return;
    }

    if (message.type === WebViewMessageTypes.DISABLE_BIOMETRICS_RESPONSE) {
      if (message.payload === undefined) {
        logPayloadIsUndefinedError();
        return;
      }

      yield* put(deleteBiometricsAsync.request(message.payload));
      return;
    }

    if (message.type === WebViewMessageTypes.BIOMETRIC_SIGNATURE_RESPONSE) {
      if (message.payload === undefined) {
        logPayloadIsUndefinedError();
        return;
      }

      yield* put(
        signWithBiometryAsync.request({
          ...message.payload,
          navigate,
        }),
      );
      return;
    }

    if (message.type === WebViewMessageTypes.SET_USER_RESPONSE) {
      if (
        message.payload === undefined ||
        isNoU(message.payload.isBiometricsEnabled)
      ) {
        logPayloadIsUndefinedError();
        return;
      }

      yield* put(setBiometrics(message.payload.isBiometricsEnabled));
      return;
    }

    if (message.type === WebViewMessageTypes.ENABLE_NOTIFICATIONS_RESPONSE) {
      if (message.payload === undefined) {
        logPayloadIsUndefinedError();
        return;
      }

      yield* put(handleEnableNotificationsResponse(message.payload));
      return;
    }

    if (message.type === WebViewMessageTypes.NOTIFICATION_TOKEN_RESPONSE) {
      if (message.payload === undefined) {
        logPayloadIsUndefinedError();
        return;
      }

      yield* put(handleNotificationTokenResponse(message.payload));
      return;
    }

    yield;
  } catch (err) {
    logError(err as Error, { actionPayload: action.payload }, false);
  }
}

function logPayloadIsUndefinedError() {
  logError(new ApplicationError("payload is undefined", ErrorLevel.Critical));
}

export function* handleOnWebViewMessageSaga() {
  yield takeLatest(getType(handleOnWebViewMessage), listenOnWebView);
}
