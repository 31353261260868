import { BlCheckboxFormField } from "Components/Shared/Inputs/Form/BlCheckboxFormField";
import { type ContactInformationFormModel } from "Models/Forms";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";

const PageResources = Resources.Contract.NewContract.ContactInformation;

const StyledWrapper = styled.div`
  margin-bottom: ${props => props.theme.spacing(2)};
`;

export const MailingAddressSelect: FunctionComponent = () => {
  const { t } = useResource();
  const {
    control,
    formState: { errors },
  } = useFormContext<ContactInformationFormModel>();

  return (
    <StyledWrapper>
      <BlCheckboxFormField
        label={t(PageResources.MailingAddressAsPermanentAddress)}
        value={true}
        control={control}
        errors={errors}
        name="mailingAddressAsPermanent"
      />
    </StyledWrapper>
  );
};
