import { BankIDProcessAudience, BankIDProcessStatus } from "Api/Api";
import { produce } from "immer";
import {
  getBankIDProfileNativeFlowAsync,
  watchGetBankIDProfileNativeFlowSaga,
} from "State/BankID/GetBankIDProfileNativeFlowState";
import { watchHandleBankIDCallbackNativeFlowSaga } from "State/BankID/HandleBankIDCallbackNativeFlowState";
import {
  handleBankIDCallbackWebFlowAsync,
  watchHandleBankIDCallbackWebFlowSaga,
} from "State/BankID/HandleBankIDCallbackWebFlowState";
import {
  initBankIDNativeFlowAsync,
  watchInitBankIDNativeFlowSaga,
} from "State/BankID/InitBankIDNativeFlowState";
import { all } from "typed-redux-saga";
import { ActionType, createAction, createReducer } from "typesafe-actions";

export type BankIDState = {
  isLoading: boolean;
  status: BankIDProcessStatus | null;
  lastRedirect: (BankIDStateParam & { redirectUri: string | null }) | null;
  error?: Error | null;
};

export interface BankIDStateParam {
  isNativeFlow: boolean;
  processID: string;
  processAudience: BankIDProcessAudience;
  [key: string]: any;
}

function getDefaultState(): BankIDState {
  return {
    isLoading: false,
    status: null,
    lastRedirect: null,
    error: null,
  };
}

export const initBankIDWebFlow = createAction(
  "@bank-id/INIT_WEB_FLOW_REQUEST",
)<{
  processID: string;
  processAudience: BankIDProcessAudience;
  redirectUri: string;
}>();

export type BankIDActionType =
  | ActionType<typeof initBankIDWebFlow>
  | ActionType<typeof initBankIDNativeFlowAsync>
  | ActionType<typeof handleBankIDCallbackWebFlowAsync>
  | ActionType<typeof handleBankIDCallbackWebFlowAsync>
  | ActionType<typeof getBankIDProfileNativeFlowAsync>;

export function* watchBankIDSagas() {
  yield all([
    watchInitBankIDNativeFlowSaga(),
    watchHandleBankIDCallbackWebFlowSaga(),
    watchHandleBankIDCallbackNativeFlowSaga(),
    watchGetBankIDProfileNativeFlowSaga(),
  ]);
}

export const bankIDReducer = createReducer<BankIDState, BankIDActionType>(
  getDefaultState(),
)
  .handleAction(handleBankIDCallbackWebFlowAsync.request, (state, action) =>
    produce(state, draft => {
      draft.isLoading = true;
      return draft;
    }),
  )
  .handleAction(handleBankIDCallbackWebFlowAsync.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.status = action.payload;
      return draft;
    }),
  )
  .handleAction(handleBankIDCallbackWebFlowAsync.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    }),
  )
  .handleAction(initBankIDNativeFlowAsync.request, (state, action) =>
    produce(state, draft => {
      const { processID, processAudience, redirectUri } = action.payload;
      return {
        ...getDefaultState(),
        isLoading: true,
        lastRedirect: {
          isNativeFlow: true,
          processID,
          processAudience,
          redirectUri,
        },
      };
    }),
  )
  .handleAction(initBankIDNativeFlowAsync.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      return draft;
    }),
  )
  .handleAction(initBankIDNativeFlowAsync.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    }),
  )
  .handleAction(getBankIDProfileNativeFlowAsync.request, (state, action) =>
    produce(state, draft => {
      draft.isLoading = true;
      return draft;
    }),
  )
  .handleAction(getBankIDProfileNativeFlowAsync.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.status = action.payload;
      return draft;
    }),
  )
  .handleAction(getBankIDProfileNativeFlowAsync.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    }),
  )
  .handleAction(initBankIDWebFlow, (state, action) =>
    produce(state, draft => {
      const { processID, processAudience, redirectUri } = action.payload;
      return {
        ...getDefaultState(),
        isLoading: true,
        lastRedirect: {
          isNativeFlow: false,
          processID,
          processAudience,
          redirectUri,
        },
      };
    }),
  );
