import { put, takeLatest } from "redux-saga/effects";
import {
  BankIDProcessAudience,
  BankIDProcessStatus,
  ProcessBankIDVerificationCommandResultStatus,
  getBankIdAuthVerify,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { NavigateFunction } from "react-router";
import { getTrackingSessionID } from "Utils/TrackingUtils";
import { setBankIDProfile } from "State/Contracts/Create/BankIDProfile/SetBankIDProfile";
import { safeApiCall } from "State/Utils";
import { resolveBankIDRedirectRoute } from "Utils/BankIDUtils";

export const handleBankIDCallbackWebFlowAsync = createAsyncAction(
  "@bank-id/HANDLE_BANK_ID_CALLBACK_WEB_FLOW_REQUEST",
  "@bank-id/HANDLE_BANK_ID_CALLBACK_WEB_FLOW_SUCCESS",
  "@bank-id/HANDLE_BANK_ID_CALLBACK_WEB_FLOW_FAILURE",
)<
  {
    processID: string;
    processAudience: BankIDProcessAudience;
    accessToken: string;
    navigate: NavigateFunction;
    t: (resourcePath: string, options?: any) => string;
  },
  BankIDProcessStatus,
  Error
>();

function* handleBankIDCallbackWebFlow(
  action: ReturnType<typeof handleBankIDCallbackWebFlowAsync.request>,
): Generator {
  try {
    const { processID, processAudience, accessToken, navigate, t } =
      action.payload;
    const { response, error } = yield* safeApiCall(
      getBankIdAuthVerify,
      processID,
      processAudience,
      accessToken,
      getTrackingSessionID(),
    );

    if (error) {
      yield put(handleBankIDCallbackWebFlowAsync.failure(error));
      return;
    }

    const { status, verificationProcess } = response;

    if (
      status === ProcessBankIDVerificationCommandResultStatus.Success &&
      verificationProcess?.profile
    ) {
      yield put(
        setBankIDProfile.request({
          profile: verificationProcess.profile,
          t,
        }),
      );

      yield put(
        handleBankIDCallbackWebFlowAsync.success(
          verificationProcess.processStatus,
        ),
      );

      navigate(resolveBankIDRedirectRoute(processAudience));
    } else {
      yield put(
        handleBankIDCallbackWebFlowAsync.failure(new Error(response.status)),
      );
    }
  } catch (err) {
    yield put(handleBankIDCallbackWebFlowAsync.failure(err as Error));
  }
}

export function* watchHandleBankIDCallbackWebFlowSaga() {
  yield takeLatest(
    getType(handleBankIDCallbackWebFlowAsync.request),
    handleBankIDCallbackWebFlow,
  );
}
