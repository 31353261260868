import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { distributionsAccessControlAsync } from "State/AccessControl/DistributionsAccessControlState";
import { isNoU, notNoU } from "Utils/ObjectUtils";
import { track } from "Utils/TrackingUtils";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router";

export const useDistributionsAccessControlRedirect = (
  redirectToPath: string | null = null,
) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isLoading, isAccessRestricted, distributionID } = useAppSelector(
    s => s.accessControl.distributions,
  );

  useEffect(() => {
    if (isNoU(isAccessRestricted)) {
      dispatch(distributionsAccessControlAsync.request());
    }

    if (isAccessRestricted && redirectToPath) {
      track({
        category: "RestrictedAccess",
        event: "Redirect",
        value: distributionID ? `Distribution ID: ${distributionID};` : null,
      });

      navigate(redirectToPath);
    }
  }, [isAccessRestricted, distributionID, redirectToPath, navigate, dispatch]);

  return useMemo(
    () => ({
      isValidated: notNoU(isAccessRestricted),
      isLoading: isLoading && isNoU(isAccessRestricted),
      isValidating: isLoading,
      isAccessRestricted: isAccessRestricted,
      distributionID: distributionID,
    }),
    [isLoading, isAccessRestricted, distributionID],
  );
};
