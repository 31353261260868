import { Stack } from "@mui/material";
import { ContractDealer } from "Components/ContractDealer/ContractDealer";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { useDistributionsAccessControlRedirect } from "Hooks/AccessControl/useDistributionsAccessControlRedirect";
import { useDesktop } from "Hooks/useDesktop";
import { Resources, useResource } from "Translations/Resources";
import { BROKER_CONSULTING_DISTRIBUTION_ID } from "Utils/DistributionUtils";
import { track } from "Utils/TrackingUtils";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router";

const PageResources = Resources.Contract.Dealer;

export const DealerPage: FunctionComponent = _ => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();
  const {
    isLoading: isLoadingAccessControl,
    isAccessRestricted,
    isValidated,
    isValidating,
    distributionID,
  } = useDistributionsAccessControlRedirect();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAccessRestricted) {
      track({
        category: "PageView",
        event: "Open",
        url: getPath(AppRouting.ContractDealer),
        value: distributionID ? `Distribution ID: ${distributionID};` : null,
      });
    }
  }, [isAccessRestricted, distributionID]);

  useEffect(() => {
    if (isValidating || !isValidated) {
      return;
    }

    if (!isAccessRestricted) {
      return navigate(getPath(AppRouting.ContractModeling));
    }

    if (
      isAccessRestricted &&
      distributionID === Number(BROKER_CONSULTING_DISTRIBUTION_ID)
    ) {
      return navigate(getPath(AppRouting.ContractDistribution));
    }
  }, [isAccessRestricted, isValidated, isValidating, distributionID, navigate]);

  return (
    <AuthenticatedLayout
      title={t(PageResources.Header)}
      isTitleShown={!isDesktop}
      onBackClick={() => navigate(getPath(AppRouting.Dashboard))}
    >
      <LoadingWrapper
        isLoading={isLoadingAccessControl}
        skeleton={
          <Stack gap={2}>
            <PrimarySkeleton fullWidth fullHeight={false} height={30} />
            <PrimarySkeleton fullWidth fullHeight={false} height={80} />
          </Stack>
        }
      >
        <ContractDealer />
      </LoadingWrapper>
    </AuthenticatedLayout>
  );
};
