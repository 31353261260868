import { Box, MenuList, Stack, Typography } from "@mui/material";
import { LAYOUT_PADDING_X } from "Constants/Layout";

import { AnalyticsItem } from "Components/Profile/Settings/components/AnalyticsItem";
import { BiometricsItem } from "Components/Profile/Settings/components/BiometricsItem";
import { NotificationItem } from "Components/Profile/Settings/components/NotificationItem";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { MenuItem } from "Components/Shared/MenuItem";
import { PageTitle } from "Components/Shared/PageTitle";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useUser } from "Hooks/useUser";
import { resetUser } from "State/Auth/AuthReducer";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { type FunctionComponent } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { useBiometricsCanBeUsed } from "Hooks/Auth/useBiometricsCanBeUsed";
import { useNotification } from "Hooks/Notifications/useNotification";

const PageResources = Resources.Profile.Settings;

const StyledTypography = styled(Typography)<{ $underline?: boolean }>`
  opacity: 0.5;
  text-decoration: ${props => (props.$underline ? "underline" : "none")};
`;

export const ProfileSettings: FunctionComponent = _ => {
  const { t } = useResource();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useUser();

  const onLogout = () => {
    dispatch(
      resetUser({
        wasSignedOutManually: true,
      }),
    );
    navigate(getPath(AppRouting.SignIn));
  };

  const { canUseBiometrics } = useBiometricsCanBeUsed();
    const { canBeUsed: canUseNotifications } = useNotification();

  return (
    <div>
      <Box marginX={LAYOUT_PADDING_X}>
        <PageTitle showOnMobile={false} marginBottom={6}>
          {t(PageResources.Title)}
        </PageTitle>

        <StyledTypography
          color="secondary"
          marginBottom={1}
          title={import.meta.env.VITE_VERSION}
        >
          {t(PageResources.LoggedUser)}
        </StyledTypography>
        <LoadingWrapper isLoading={false} wrapChildren fullWidth>
          <StyledTypography color="secondary" fontWeight={600} $underline>
            {user?.login ?? "@"}
          </StyledTypography>
        </LoadingWrapper>
      </Box>

      <MenuList>
        <LoadingWrapper
          isLoading={false}
          skeleton={
            <Stack gap={3} marginX={LAYOUT_PADDING_X} marginTop={4}>
              <PrimarySkeleton height={80} />
              <PrimarySkeleton height={80} />
            </Stack>
          }
        >
          {canUseBiometrics && <BiometricsItem />}
          {canUseNotifications && !user?.isUnderage && <NotificationItem />}
          {!user?.isUnderage && <AnalyticsItem />}
        </LoadingWrapper>
        <MenuItem
          title={t(PageResources.ChangePassword)}
          link={getPath(AppRouting.ProfileChangePassword)}
        />
        <MenuItem
          title={t(PageResources.DeleteAccount.Title)}
          link={getPath(AppRouting.ProfileDeleteAccount)}
        />
        <MenuItem title={t(PageResources.Logout)} onClick={onLogout} />
      </MenuList>
    </div>
  );
};
