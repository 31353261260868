import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { ProfileSettings } from "Components/Profile/Settings/ProfileSettings";
import { useDesktop } from "Hooks/useDesktop";
import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";
import { useNavigate } from "react-router";

export const SettingsPage: FunctionComponent = _ => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();
  const navigate = useNavigate();

  return (
    <AuthenticatedLayout
      title={t(Resources.Profile.Settings.Title)}
      isTitleShown={!isDesktop}
      onBackClick={() => navigate(-1)}
      disableContentPadding
    >
      <ProfileSettings />
    </AuthenticatedLayout>
  );
};
