import {
  ContractRedemptionCommand,
  ContractRedemptionCommandCommandResultStatus,
  getUserContact,
  postContractRedemption,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { safeApiCall } from "State/Utils";
import { ApplicationError } from "Models/Errors/ApplicationError";
import { initializeSmsSignatureAsync } from "State/Contracts/Create/SignatureSms/InitializeSmsSignatureState";
import {
  setBiometricsSignatureType,
  BiometricsSignatureType,
} from "State/Biometrics/BiometricsActions";
import { initializeBiometricSignatureAsync } from "State/Contracts/Biometrics/InitializeBiometricSignature";

export const redeemAsync = createAsyncAction(
  "@contract/REDEEM_REQUEST",
  "@contract/REDEEM_SUCCESS",
  "@contract/REDEEM_FAILURE",
)<
  ContractRedemptionCommand,
  { signatureHash: string; phone?: string; type: "SMS" | "BIOMETRICS" },
  Error
>();

function* redeem(action: ReturnType<typeof redeemAsync.request>): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postContractRedemption,
      action.payload,
    );

    if (error) {
      yield put(redeemAsync.failure(error));
      return;
    }

    if (
      response.status !== ContractRedemptionCommandCommandResultStatus.Success
    ) {
      yield put(
        redeemAsync.failure(
          new ApplicationError(`Redemption create failed, ${response.status}`),
        ),
      );
      return;
    }

    const signatureHash = response.signatureHashList[0];

    if (response.signatureHashList?.length !== 1) {
      yield put(
        redeemAsync.failure(
          new ApplicationError("More than one signature hash was returned"),
        ),
      );
    }

    if (action.payload.isBiometry) {
      yield put(setBiometricsSignatureType(BiometricsSignatureType.Redemption));

      yield put(
        initializeBiometricSignatureAsync.request({
          signatureHash,
        }),
      );

      yield put(
        redeemAsync.success({
          type: "BIOMETRICS",
          signatureHash: signatureHash,
        }),
      );

      return;
    }

    const { response: userContactResponse, error: userContactError } =
      yield* safeApiCall(getUserContact);

    if (userContactError) {
      yield put(
        redeemAsync.failure(
          new ApplicationError(`UserContact was not fetched successfully.`),
        ),
      );
      return;
    }

    yield put(initializeSmsSignatureAsync.request({ signatureHash }));

    yield put(
      redeemAsync.success({
        type: "SMS",
        signatureHash: signatureHash,
        phone: userContactResponse.userContactInfo.phone!,
      }),
    );
  } catch (err) {
    yield put(redeemAsync.failure(err as Error));
  }
}
export function* redeemSaga() {
  yield takeLatest(getType(redeemAsync.request), redeem);
}
