import { List } from "@mui/material";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { MenuItem } from "Components/Shared/MenuItem";
import { useDesktop } from "Hooks/useDesktop";
import { ResourceDictionary } from "Translations/ResourceDictionary";
import { Resources, useResource } from "Translations/Resources";
import { getPath, AppRouting } from "Utils/UrlUtils";
import { type FunctionComponent } from "react";

const PageResources = Resources.More.Menu;

type MenuItemType = {
  title: string;
  description: string;
  link: string;
};

// we will use the menu items from the resource as the basis for typing the menu items,
// so we have guaranteed that all items for translations have their own item in the menu and vice versa
type MenuResourceItems = keyof ResourceDictionary["More"]["Menu"];

const getResources = (
  resource: MenuResourceItems,
): Pick<MenuItemType, "title" | "description"> => {
  const { Title, Description } = PageResources[resource];

  return {
    title: Title,
    description: Description,
  };
};

const MenuItemsRoutes: {
  [key in MenuResourceItems]: { url: string; isExternal?: boolean };
} = {
  News: {
    url: "https://moje.investika.cz/novinky",
    isExternal: true,
  },
  PersonalData: {
    url: getPath(AppRouting.PersonalData),
  },
  InvestikaInformation: {
    url: "https://moje.investika.cz/o-spolecnosti",
    isExternal: true,
  },
  Documents: {
    url: getPath(AppRouting.MoreDocuments),
  },
  Contacts: {
    url: getPath(AppRouting.Contacts),
  },
  About: {
    url: getPath(AppRouting.About),
  },
};

const MenuItems = (
  Object.entries(MenuItemsRoutes) as [
    MenuResourceItems,
    { url: string; isExternal?: boolean },
  ][]
).map(([resource, route]) => ({
  ...getResources(resource),
  route,
}));

export const MorePage: FunctionComponent = _ => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();
  return (
    <AuthenticatedLayout
      title={t(Resources.Navigation.More)}
      isBottomMenuShown
      disableContentPadding
      isLogoShown={isDesktop}
      isControlsShown
    >
      <List>
        {MenuItems.map(({ route, description, title }, index) => (
          <MenuItem
            key={index}
            link={route.url}
            description={t(description)}
            title={t(title)}
            isExternal={route.isExternal}
          />
        ))}
      </List>
    </AuthenticatedLayout>
  );
};

export default MorePage;
