import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import { decodeBankIDStateParam } from "Utils/BankIDUtils";
import { useMemo } from "react";

export const useBankIDCallbackParams = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const error = searchParams.get("error");

  const { accessToken, state, decodedState } = useMemo(() => {
    const params = new URLSearchParams(location.hash.replace("#", ""));

    const accessToken = params.get("access_token");
    const state = params.get("state");
    const decodedState = decodeBankIDStateParam(state);

    return { accessToken, state, decodedState };
  }, [location.hash]);

  return {
    state,
    decodedState,
    accessToken,
    error,
  };
};
