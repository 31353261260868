import { Box, Typography } from "@mui/material";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PageTitle } from "../Shared/PageTitle";
import { PaymentInformations } from "Components/Shared/PaymentInformations/PaymentInformations";
import { PaymentLoadingSkeleton } from "Components/Shared/PaymentInformations/PaymentLoadingSkeleton";
import { useFormData } from "Hooks/Investment/NewInvestment/useFormData";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useFormData as useContractFormData } from "Hooks/Contract/useFormData";
import { useAppSelector } from "Hooks/useAppSelector";
import {
  clearPaymentInstructions,
  getPaymentInstructionsAsync,
} from "State/Contracts/Contract/PaymentInstructions/GetPaymentInstructionsState";
import { NewContractStep } from "State/Contracts/Create/Models";
import { ContractModelingStep } from "State/Contracts/Modeling/Models";
import { resetNewContract } from "State/Contracts/Shared/Actions";
import { Resources, useResource } from "Translations/Resources";
import { getPath, AppRouting } from "Utils/UrlUtils";
import { addYears } from "date-fns";
import { useEffect, type FunctionComponent } from "react";
import { useNavigate } from "react-router";
import { resetDashboardContracts } from "State/Dashboard/Contracts/GetContractsState";
import { getOverviewAsync } from "State/InvestmentsDetail/Overview/GetOverviewState";
import { useIsDipSelected } from "Hooks/Contract/useIsDipSelected";
import { ProductLogo } from "Components/Shared/ProductLogo";
import { useModelingColor } from "Hooks/useModelingColor";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";

const PageResources = Resources.Contract.NewContract.FinalPage;

export const FinalPage: FunctionComponent = () => {
  const { t } = useResource();
  const { color, contrastColor } = useModelingColor();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isDip = useIsDipSelected();

  const { defaultValues: contractSignature } =
    useContractFormData<NewContractStep.ContractSignature>({
      step: NewContractStep.ContractSignature,
    });

  const {
    data,
    isLoading: isLoadingPaymentInstruction,
    error,
  } = useAppSelector(s => s.contracts.contract.paymentInstruction);

  const { defaultValues } = useFormData<ContractModelingStep.FundConfiguration>(
    {
      step: ContractModelingStep.FundConfiguration,
    },
  );
  const { defaultValues: fund } = useFormData<ContractModelingStep.Fund>({
    step: ContractModelingStep.Fund,
  });

  useEffect(() => {
    if (contractSignature?.contractID && fund?.isin) {
      dispatch(
        getPaymentInstructionsAsync.request({
          contractID: contractSignature?.contractID,
          isins: [fund.isin],
        }),
      );
    }

    return () => {
      dispatch(clearPaymentInstructions());
    };
  }, [contractSignature, fund, dispatch]);

  const onComplete = () => {
    // clean up the redux state
    dispatch(resetDashboardContracts());
    dispatch(resetNewContract());
    navigate(getPath(AppRouting.Dashboard));
  };

  const { isLoading: isLoadingOverview, data: overview } = useAppSelector(
    state => state.investmentsDetail.overview,
  );

  useEffect(() => {
    if (!contractSignature?.contractID) {
      return;
    }

    dispatch(getOverviewAsync.request(contractSignature.contractID));
  }, [dispatch, contractSignature?.contractID]);

  const isLoading =
    isLoadingPaymentInstruction ||
    !contractSignature?.contractID ||
    (isLoadingOverview && !overview);

  const productIsin = data?.[0]?.paymentInstructions[0]?.isin || undefined;

  return (
    <>
      <div>
        <PageTitle>{t(PageResources.Title)}</PageTitle>
        <Typography>{t(PageResources.Label)}</Typography>

        <LoadingWrapper
          isLoading={isLoading}
          error={error}
          skeleton={<PaymentLoadingSkeleton />}
        >
          {productIsin && (
            <Box marginTop={6}>
              <ProductLogo isin={productIsin} imageHeight={30} />
            </Box>
          )}

          {contractSignature?.contractID && (
            <PaymentInformations
              contractID={contractSignature.contractID}
              bankAccountRegion={overview?.bankAccountRegion}
              isinPaymentsInfo={
                productIsin
                  ? {
                      [productIsin]: {
                        singleInvestment: defaultValues?.singleInvestment
                          ? defaultValues.singleInvestment
                          : undefined,
                        periodicalInvestment:
                          defaultValues?.periodicalInvestment
                            ? defaultValues.periodicalInvestment
                            : undefined,
                        expiryDate: defaultValues?.investmentLengthYears
                          ? addYears(
                              new Date(),
                              defaultValues.investmentLengthYears,
                            )
                          : undefined,
                      },
                    }
                  : {}
              }
              isNoticeToFirstPaymentVisible={
                data?.[0]?.isNoticeToFirstPaymentVisible
              }
              contractPaymentMessage={
                data?.[0]?.contractPaymentMessage || undefined
              }
              defaultBankAccount={data?.[0]?.defaultBankAccount || undefined}
              paymentInstructions={data?.[0]?.paymentInstructions}
              contractTypeCode={overview?.type}
              documents={data?.[0]?.documents || undefined}
              isDip={isDip}
              isDocumentsReferenceVisible={true}
              useProductColors={true}
              showSingleInvestment={!!defaultValues?.singleInvestment}
              showSingleInvestmentProductLogo={false}
              showPeriodicalInvestment={!!defaultValues?.periodicalInvestment}
              showPeriodicalInvestmentProductLogo={false}
              showDivider={false}
            />
          )}
        </LoadingWrapper>
      </div>
      <PrimaryButton
        color="primary"
        onClick={onComplete}
        trackingTag={t(PageResources.ButtonText)}
        hexColor={contrastColor}
        hexBackgroundColor={color}
      >
        {t(PageResources.ButtonText)}
      </PrimaryButton>
    </>
  );
};
