import {
  BankIDCallbackLoadingWrapper,
  BankIDCallbackLoadingWrapperProps,
} from "Components/BankID/BankIDCallbackLoadingWrapper";
import { useBankIDCallbackParams } from "Hooks/BankID/useBankIDCallbackParams";
import { useAppSelector } from "Hooks/useAppSelector";
import { handleBankIDCallbackWebFlowAsync } from "State/BankID/HandleBankIDCallbackWebFlowState";
import { useResource } from "Translations/Resources";
import { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

export type BankIDCallbackWebProps = Pick<
  BankIDCallbackLoadingWrapperProps,
  "translations"
> & {
  onError: () => void;
};

export const BankIDCallbackWeb: FunctionComponent<BankIDCallbackWebProps> = ({
  onError,
  ...props
}) => {
  const { t } = useResource();
  const { error: bankIDError } = useAppSelector(s => s.bankID);
  const { accessToken, decodedState, error } = useBankIDCallbackParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken && decodedState) {
      dispatch(
        handleBankIDCallbackWebFlowAsync.request({
          processID: decodedState.processID,
          processAudience: decodedState.processAudience,
          accessToken,
          navigate,
          t,
        }),
      );
    }
  }, [accessToken, decodedState, dispatch, navigate, t]);

  useEffect(() => {
    if (error === "access_denied") {
      onError();
    }
  }, [error, onError]);

  const isError: boolean = !!error || !!bankIDError;

  return <BankIDCallbackLoadingWrapper isError={isError} {...props} />;
};
