import {
  DistributionsAccessControlQueryResult,
  DistributionsAccessControlQueryResultStatus,
  getAccessControlDistributions,
} from "Api/Api";
import { safeApiCall } from "State/Utils";
import { produce } from "immer";
import { put, takeLeading } from "typed-redux-saga";
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
  getType,
} from "typesafe-actions";

export type DistributionsAccessControlState = {
  isLoading: boolean;
  isAccessRestricted: boolean | null;
  distributionID: number | null;
  error: Error | null;
};

function getDefaultState(): DistributionsAccessControlState {
  return {
    isLoading: false,
    isAccessRestricted: null,
    distributionID: null,
    error: null,
  };
}

export const resetDistributionsAccessControlState = createAction(
  "@access_control/RESET_DISTRIBUTIONS_STATE",
)<boolean>();

export const distributionsAccessControlAsync = createAsyncAction(
  "@access_control/DISTRIBUTIONS_REQUEST",
  "@access_control/DISTRIBUTIONS_SUCCESS",
  "@access_control/DISTRIBUTIONS_FAILURE",
)<void, DistributionsAccessControlQueryResult, Error>();

export type DistributionsAccessControlActionType =
  | ActionType<typeof distributionsAccessControlAsync>
  | ActionType<typeof resetDistributionsAccessControlState>;

function* distributionsAccessControl(
  action: ReturnType<typeof distributionsAccessControlAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      getAccessControlDistributions,
    );

    if (error) {
      yield put(distributionsAccessControlAsync.success(error.response));
      return;
    }

    yield put(distributionsAccessControlAsync.success(response));
  } catch (err) {
    yield put(distributionsAccessControlAsync.failure(err as Error));
  }
}

export function* watchDistributionsAccessControlSaga() {
  yield takeLeading(
    getType(distributionsAccessControlAsync.request),
    distributionsAccessControl,
  );
}

export const distributionsAccessControlReducer = createReducer<
  DistributionsAccessControlState,
  DistributionsAccessControlActionType
>(getDefaultState())
  .handleAction(resetDistributionsAccessControlState, state => {
    return produce(state, () => {
      return getDefaultState();
    });
  })
  .handleAction(distributionsAccessControlAsync.request, state => {
    return produce(state, draft => {
      draft.isLoading = true;
      draft.error = null;
      return draft;
    });
  })
  .handleAction(
    distributionsAccessControlAsync.success,
    (state, { payload: { status, distributionID } }) => {
      return produce(state, draft => {
        draft.isLoading = false;
        draft.distributionID = distributionID || null;

        switch (status) {
          case DistributionsAccessControlQueryResultStatus.AccessAllowed:
            draft.isAccessRestricted = false;
            break;
          case DistributionsAccessControlQueryResultStatus.AccessRestricted:
            draft.isAccessRestricted = true;
            break;
          case DistributionsAccessControlQueryResultStatus.MoreThan1000ContractsFound:
          case DistributionsAccessControlQueryResultStatus.Fail:
            draft.isAccessRestricted = null;
            break;
        }

        return draft;
      });
    },
  )
  .handleAction(distributionsAccessControlAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.isAccessRestricted = state.isAccessRestricted ? true : null;
      draft.error = action.payload;
      return draft;
    });
  });
