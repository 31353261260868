import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { PersonalData } from "Components/More/PersonalData/PersonalData";
import { useDesktop } from "Hooks/useDesktop";
import { Resources, useResource } from "Translations/Resources";
import { getPath, AppRouting } from "Utils/UrlUtils";
import { type FunctionComponent } from "react";
import { useNavigate } from "react-router";

export const PersonalDataPage: FunctionComponent = _ => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();
  const navigate = useNavigate();

  return (
    <AuthenticatedLayout
      title={t(Resources.More.Menu.PersonalData.Title)}
      isTitleShown={!isDesktop}
      onBackClick={() => navigate(getPath(AppRouting.More))}
    >
      <PersonalData />
    </AuthenticatedLayout>
  );
};
