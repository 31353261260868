import { Box } from "@mui/material";
import { BankIDCallbackLoadingWrapper } from "Components/BankID/BankIDCallbackLoadingWrapper";
import { BankIDCallbackNative } from "Components/BankID/BankIDCallbackNative";
import {
  BankIDCallbackWeb,
  BankIDCallbackWebProps,
} from "Components/BankID/BankIDCallbackWeb";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PageTitle } from "Components/Shared/PageTitle";
import { useAppSelector } from "Hooks/useAppSelector";
import { FunctionComponent, useEffect, useMemo, useCallback } from "react";
import { isWebView } from "Utils/WebViewUtils";

type Props = BankIDCallbackWebProps & {
  productColor?: string | null;
  productContrastColor?: string | null;
  translations: {
    title: string;
    backButton: string;
  };
  onBackClick: () => void;
};

export const BankIDCallback: FunctionComponent<Props> = props => {
  const { error } = useAppSelector(s => s.bankID);
  const {
    productColor,
    productContrastColor,
    translations,
    onError,
    onBackClick,
  } = props;

  const isNativeFlow = useMemo(() => isWebView(), []);
  const { lastRedirect } = useAppSelector(s => s.bankID);

  useEffect(() => {
    document.title = translations.title;
  }, [translations.title]);

  useEffect(() => {
    if (error) {
      onError();
    }
  }, [error, onError]);

  const handleBackClick = useCallback(() => {
    onBackClick();
  }, [onBackClick]);

  const isInvalidState =
    !lastRedirect ||
    (!lastRedirect.isNativeFlow && isNativeFlow) ||
    (lastRedirect.isNativeFlow && !isNativeFlow);

  return (
    <UnauthenticatedLayout title={translations.title}>
      <Box marginBottom="auto">
        <PageTitle>{translations.title}</PageTitle>
        {!isInvalidState && isNativeFlow && <BankIDCallbackNative {...props} />}
        {!isInvalidState && !isNativeFlow && <BankIDCallbackWeb {...props} />}
        {/* Show Bank ID loader until WebView is ready. */}
        {isInvalidState && (
          <BankIDCallbackLoadingWrapper
            isError={false}
            translations={{
              description: translations.description,
              error: translations.error,
            }}
          />
        )}
      </Box>

      <PrimaryButton
        hexColor={productContrastColor}
        hexBackgroundColor={productColor}
        fullWidth
        onClick={handleBackClick}
      >
        {translations.backButton}
      </PrimaryButton>
    </UnauthenticatedLayout>
  );
};
