import { Typography } from "@mui/material";
import { BankIDProcessAudience } from "Api/Api";
import { useAppDispatch } from "Hooks/useAppDispatch";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { initBankIDWebFlow } from "State/BankID/BankIDReducer";
import { initBankIDNativeFlowAsync } from "State/BankID/InitBankIDNativeFlowState";
import { encodeBankIDStateParam } from "Utils/BankIDUtils";
import { isWebView } from "Utils/WebViewUtils";

const WEB_BANK_ID_URL = import.meta.env.VITE_WEB_BANK_ID_URL;
const NATIVE_BANK_ID_URL = import.meta.env.VITE_NATIVE_BANK_ID_URL;

type Props = {
  isActive?: boolean;
  processAudience: BankIDProcessAudience;
  translations: {
    redirecting: string;
  };
};

export const BankIDRedirect: FunctionComponent<Props> = ({
  isActive = true,
  processAudience,
  translations: { redirecting },
}) => {
  const isRedirected = useRef(false);
  const isNativeFlow = useMemo(() => isWebView(), []);

  const dispatch = useAppDispatch();

  const redirectUriBase = useMemo(() => {
    return isNativeFlow ? NATIVE_BANK_ID_URL : WEB_BANK_ID_URL;
  }, [isNativeFlow]);

  const [{ processID, redirectUri }] = useState(
    encodeBankIDStateParam(isNativeFlow, redirectUriBase, processAudience),
  );

  const redirectToBankID = useCallback(() => {
    if (!redirectUri || isRedirected.current) {
      return;
    }

    isRedirected.current = true;

    if (isNativeFlow) {
      dispatch(
        initBankIDNativeFlowAsync.request({
          processID,
          processAudience,
          redirectUri,
        }),
      );
    } else {
      dispatch(initBankIDWebFlow({ processID, processAudience, redirectUri }));
      window.location.href = redirectUri;
    }
  }, [isNativeFlow, processID, processAudience, redirectUri, dispatch]);

  useEffect(() => {
    if (isActive) {
      redirectToBankID();
    }
  }, [isActive, redirectToBankID]);

  return <Typography>{redirecting}</Typography>;
};
